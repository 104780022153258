import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticStyle:{"background-color":"white !important"},attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"d-flex flex-column align-start justify-start"},[_c('h4',{staticClass:"font-weigth-bold mb-2"},[_vm._v(" "+_vm._s(_vm.item && _vm.item.titulo !== undefined ? _vm.item.titulo : "")+" ")]),(_vm.role)?_c('h6',{staticClass:"ma-2 font-weight-medium"},[_c('strong',[_vm._v("Responsável:")]),_vm._v(" "+_vm._s(_vm.item.responsavel.nome)+" ")]):_vm._e()]),(_vm.item && _vm.item.status !== undefined)?_c(VChip,{staticClass:"font-weight-medium",attrs:{"label":"","color":_vm.status &&
          _vm.status[_vm.item.status].color !== undefined &&
          _vm.status[_vm.item.status].color
          ? _vm.status[_vm.item.status].color
          : ''}},[_vm._v(_vm._s(_vm.status && _vm.status[_vm.item.status].text !== undefined && _vm.status[_vm.item.status].text ? _vm.status[_vm.item.status].text : ""))]):_vm._e()],1),_c(VCardText,{attrs:{"tag":"main"}},[_c(VTabs,{staticClass:"border-bottom",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabsSlider,{attrs:{"color":"#162C40"}}),_vm._l((_vm.TabsVisualizarConfiguracao),function(tab){return _c(VTab,{key:tab.id,staticClass:"primary--text",attrs:{"href":("#tab-" + (tab.id))}},[_c('span',{staticClass:"color-legiio"},[_vm._v(_vm._s(tab.nome))])])})],2),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.TabsVisualizarConfiguracao),function(tabItem){return _c(VTabItem,{key:tabItem.id + 1,staticClass:"ma-4",attrs:{"value":'tab-' + tabItem.id}},[_c(tabItem.componente,{tag:"component"})],1)}),1)],1),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"text-right",attrs:{"color":"#162c40","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }