<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="text-h5"> Duplicar Avaliação </v-card-title>
      <v-card-subtitle v-if="avaliacao" class="my-1 ml-2 text-subtitle-1">
        {{ avaliacao.titulo }}
      </v-card-subtitle>
      <v-card-text class="d-flex flex-column">
        <h3 class="mb-3">Deseja duplicar os dados atrelados a avaliação?</h3>
        <div
          v-for="(duplicate, i) in duplicates"
          :key="i"
          class="d-flex flex-column"
        >
          <span>{{ duplicate.text }}</span>
          <v-switch
            v-model="duplicateToo[duplicate.value]"
            :true-value="true"
            :false-value="false"
            class="mt-0"
          ></v-switch>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close()"> Fechar </v-btn>
        <v-btn :loading="loading" color="success" @click="duplicate()">
          Duplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import store from "@/store";

export default {
  name: "ModalDuplicarAvaliacao",
  props: {
    dialog: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  setup() {
    // Variables
    const duplicateToo = ref({
      configuracao_comportamental: false,
      competencia: false,
      meta: false,
    });
    const duplicates = ref([
      {
        text: "Configuração Comportamental",
        value: "configuracao_comportamental",
      },
      {
        text: "Competência",
        value: "competencia",
      },
      {
        text: "Metas",
        value: "meta",
      },
    ]);
    const loading = ref(false);

    // Computed
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);

    function duplicate() {
      loading.value = true;

      const data = {
        uuid: avaliacao.value.uuid,
        data: duplicateToo.value,
      };

      store
        .dispatch("avdAvaliacoes/duplicateEvaluation", data)
        .then((response) => {
          this.$router.push({
            name: "avd-configuracao-view",
            params: { uuid: response.uuid },
          });
        })
        .catch(() => {
          store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Ocorreu um erro ao duplicar a avaliação! Por favor, tente novamente ou entre em contato com o suporte.",
            cor: "red",
          });
        })
        .finally(() => {
          loading.value = false;
          store.dispatch("avd/resetAvaliacao");
          this.$emit("close-modal-duplicar");
        });
    }
    function close() {
      store.dispatch("avd/resetAvaliacao");
      this.$emit("close-modal-duplicar");
    }

    return {
      // Variables
      duplicateToo,
      duplicates,
      loading,

      // Computed
      avaliacao,

      // Function
      duplicate,
      close,
    };
  },
};
</script>

<style></style>
