<template>
  <section name="avaliacoes">
    <v-row>
      <v-col cols="12">
        <Crud title="Gestão de Avaliações" buttonAddItemText="Nova avaliação" searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField" :callBackModalInsert="redirectNewEvaluation"
          :dialogDelete.sync="dialogDelete">
          <template slot="data-table">
            <v-data-table :headers="headers" :items="avaliacoes" class="elevation-1" :loading="loading"
              items-per-page-text="Linhas por página" no-results-text="Nenhum registro encontrado"
              no-data-text="Nenhum registro cadastrado" loading-text="Carregando... Por favor, aguarde" :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }">

              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="item.status == 'E' ? false : true" @click="editar(item)" icon v-bind="attrs" v-on="on">
                              <v-icon class="actions"> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Duplicar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="item.status == 'A' ? false : true" @click="duplicar(item)" icon v-bind="attrs" v-on="on">
                              <v-icon class="actions"> mdi-content-duplicate </v-icon>
                            </v-btn>
                          </template>
                          <span>Duplicar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Remover -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="remover(item, true)" icon v-bind="attrs" v-on="on">
                              <v-icon class="actions"> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- DATA INICIO -->
              <template v-slot:[`item.data_hora_inicio`]="{ item }">
                <span>
                  {{ item.data_hora_inicio | dateFormatBr() }}
                </span>
              </template>
              <!-- CREATED_AT -->
              <template v-slot:[`item.created_at`]="{ item }">
                <span>
                  {{ item.created_at | dateFormatBr() }}
                </span>
              </template>
              <!-- STATUS -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip label class="font-weight-medium" :color="status[item.status].color">{{
                  status[item.status].text
                }}</v-chip>
              </template>
              <!-- Avaliacoes -->
              <template v-slot:[`item.avaliacoes`]="{ item }">
                <v-chip-group>
                  <v-chip small color="info" v-if="item.comportamental == 'A'">Comportamental</v-chip>
                  <v-chip small color="info" v-if="item.tecnica == 'A'">Técnica</v-chip>
                </v-chip-group>
              </template>
              <!-- RESPONSAVEIS -->
              <template v-slot:[`item.responsavel`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar v-if="item.responsavel.avatar" color="primary" class="v-avatar-light-bg primary--text"
                    size="32">
                    <img :src="item.responsavel.avatar" :alt="'Foto de ' + item.responsavel.nome"></img>
                  </v-avatar>
                  <v-avatar v-else color="primary" class="v-avatar-light-bg primary--text" size="32">
                    <span class="font-weight-medium">{{ avatarText(item.responsavel.nome) }}</span>
                  </v-avatar>
                  <div class="d-flex flex-column ms-3">
                    <span class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                      {{ item.responsavel.nome }}
                    </span>
                    <small>{{ item.responsavel.email }}</small>
                  </div>
                </div>
              </template>
              <template v-slot:[`footer.page-text`]="item">
                {{ item.pageStart }} - {{ item.pageStop }} de
                {{ item.itemsLength }}
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn :loading="loadingButtonDelete" color="error" @click="remover()">
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalVisualizarAvaliacaoComponent v-if="dialogModal" @close-dialog="closeDialog()" :item="dataDialog"
      :dialog="dialogModal" />
    <ModalDuplicarAvaliacao :dialog="dialogModalDuplicar" @close-modal-duplicar="dialogModalDuplicar = false" />
  </section>
</template>

<script>
import ModalVisualizarAvaliacaoComponent from "./../components/ModalVisualizarAvaliacaoComponent.vue";
import ModalDuplicarAvaliacao from '../components/ModalDuplicarAvaliacao.vue'
import Crud from "@/components/Crud.vue";

export default {
  name: "avd-avaliacao-view",
  components: {
    ModalVisualizarAvaliacaoComponent,
    Crud,
    ModalDuplicarAvaliacao,
  },
  data: () => ({
    searchTextField: "",
    dialogModal: false,
    dataDialog: {
      uuid: "",
      nome: "",
      responsavel: {
        nome: "",
        foto: "",
      },
    },
    headers: [
      { text: "Título", align: "start", value: "titulo" },
      { text: "Responsável", align: "start", value: "responsavel" },
      { text: "Periodicidade", align: "start", value: "periodicidade" },
      { text: "Data início", align: "start", value: "data_hora_inicio" },
      { text: "Avaliações", align: "start", value: "avaliacoes" },
      { text: "Status", value: "status" },
      { text: "Data de criação", value: "created_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    // Modal de delete
    dialogDelete: false,
    loadingButtonDelete: false,
    item: [],

    // Modal de duplicar
    dialogModalDuplicar: false
  }),

  created() {
    this.$store.dispatch("avd/initializeAvaliacao");
  },

  computed: {
    avaliacoes: {
      get() {
        return this.$store.getters["avd/getAvaliacoes"];
      },
      set(value) {
        return value;
      },
    },
    avaliacao: {
      get() {
        return this.$store.getters["avd/getAvaliacao"];
      },
      set(value) {
        this.$store.dispatch("avd/setAvaliacao", value);
      },
    },
    loading: {
      get() {
        return this.$store.getters["avd/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    status: {
      get() {
        return this.$store.getters["avd/getStatusAvaliacao"];
      },
    },
    tipos: {
      get() {
        return this.$store.getters["avd/getTiposAvaliacao"];
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },

  methods: {
    openDialog(item) {
      const itemData = {
        uuid: item.uuid,
      };

      this.$store.dispatch("avd/buscarDadosDaAvaliacao", itemData);

      this.dialogModal = true;
      this.dataDialog = item;
    },
    closeDialog() {
      this.dialogModal = false;
      this.dataDialog = {
        uuid: "",
        nome: "",
        responsavel: {
          nome: "",
          foto: "",
        },
        status: "A",
      };
    },
    redirectNewEvaluation(uuid = null) {
      // Precisa validar se o usuário estiver editando pois, caso estiver a req de avaliacao será buscada aqui!
      this.$router.push({
        name: "avd-configuracao-view",
        params: { uuid: uuid },
      });
    },
    editar(item) {
      this.avaliacao = item;
      let tipos = {
        COM: (this.avaliacao.comportamental) ? true : false,
        TEC: (this.avaliacao.tecnica) ? true : false
      };
      this.avaliacao.dataInicio = this.avaliacao.data_hora_inicio;
      this.avaliacao.tipo_avaliacao = tipos;
      this.redirectNewEvaluation(item.uuid);
    },
    fechar() {
      this.$store.dispatch("avd/clearDataAvaliacao");
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.avaliacoes.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("avd/deleteEvaluation", this.item.uuid)
          .then((response) => {
            if (response) {
              this.avaliacoes.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Avaliação removida.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover a avaliação.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    duplicar(item) {
      this.avaliacao = item;
      this.dialogModalDuplicar = true
    }
  },
};
</script>

<style scoped></style>
