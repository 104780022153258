import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Duplicar Avaliação ")]),(_vm.avaliacao)?_c(VCardSubtitle,{staticClass:"my-1 ml-2 text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.avaliacao.titulo)+" ")]):_vm._e(),_c(VCardText,{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"mb-3"},[_vm._v("Deseja duplicar os dados atrelados a avaliação?")]),_vm._l((_vm.duplicates),function(duplicate,i){return _c('div',{key:i,staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(duplicate.text))]),_c(VSwitch,{staticClass:"mt-0",attrs:{"true-value":true,"false-value":false},model:{value:(_vm.duplicateToo[duplicate.value]),callback:function ($$v) {_vm.$set(_vm.duplicateToo, duplicate.value, $$v)},expression:"duplicateToo[duplicate.value]"}})],1)})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Fechar ")]),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"success"},on:{"click":function($event){return _vm.duplicate()}}},[_vm._v(" Duplicar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }