<template>
  <v-dialog v-model="dialog">
    <v-card :loading="loading" style="background-color: white !important">
      <v-card-title class="d-flex flex-row justify-space-between">
        <!-- Nome da avaliação e nome do colaborador -->
        <div class="d-flex flex-column align-start justify-start">
          <h4 class="font-weigth-bold mb-2">
            {{ item && item.titulo !== undefined ? item.titulo : "" }}
          </h4>
          <h6 v-if="role" class="ma-2 font-weight-medium">
            <strong>Responsável:</strong> {{ item.responsavel.nome }}
          </h6>
        </div>
        <!-- Status -->
        <v-chip v-if="item && item.status !== undefined" label class="font-weight-medium" :color="status &&
            status[item.status].color !== undefined &&
            status[item.status].color
            ? status[item.status].color
            : ''
          ">{{
    status &&
    status[item.status].text !== undefined &&
    status[item.status].text
    ? status[item.status].text
    : ""
  }}</v-chip>
      </v-card-title>

      <v-card-text tag="main">
        <v-tabs class="border-bottom" v-model="tabs">
          <v-tabs-slider color="#162C40"></v-tabs-slider>
          <v-tab v-for="tab in TabsVisualizarConfiguracao" :key="tab.id" :href="`#tab-${tab.id}`" class="primary--text">
            <span class="color-legiio">{{ tab.nome }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item class="ma-4" v-for="tabItem in TabsVisualizarConfiguracao" :key="tabItem.id + 1"
            :value="'tab-' + tabItem.id">
            <component :is="tabItem.componente"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn class="text-right" color="#162c40" text @click="closeDialog()">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalVisualizarAvaliacaoComponent",
  props: {
    dialog: {
      default: false,
    },
    item: {
      default: {
        uuid: "",
        titulo: "",
        responsavel: {
          nome: "",
          foto: null,
        },
        status: "C",
      },
    },
  },

  data() {
    return {
      tabs: "tab-1",
      loading: false,
    };
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("avd/fullDataAvaliacao", this.item.uuid)
      .then()
      .catch(() => {
        this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
          visibilidade: true,
          mensagem:
            "Houve um problema em carregar avaliação. Por favor, tente mais tarde.",
          cor: "red",
        });

        this.closeDialog();
        this.$destroy();
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    closeDialog() {
      this.tabs = "tab-1";
      this.$emit("close-dialog");
      this.$store.dispatch("avd/clearDadosDeVisualizarAvaliacao");
    },
  },

  computed: {
    status: {
      get() {
        return this.$store.getters["avd/getStatusAvaliacao"];
      },
    },
    TabsVisualizarConfiguracao: {
      get() {
        return this.$store.getters[
          "avd/getTabsVisualizarConfiguracaoAvaliacao"
        ];
      },
    },
    role() {
      const role = this.$store.getters["auth/getRole"];

      if (
        role == "Desenvolvedor" ||
        role == "Administrador" ||
        role == "Recursos Humanos"
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.border-radius-full-custom {
  border-radius: 50%;
}
</style>
