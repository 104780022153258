import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"name":"avaliacoes"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":"Gestão de Avaliações","buttonAddItemText":"Nova avaliação","searchLabelText":"Pesquisar na lista","searchTextField":_vm.searchTextField,"callBackModalInsert":_vm.redirectNewEvaluation,"dialogDelete":_vm.dialogDelete},on:{"update:searchTextField":function($event){_vm.searchTextField=$event},"update:search-text-field":function($event){_vm.searchTextField=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.avaliacoes,"loading":_vm.loading,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","no-data-text":"Nenhum registro cadastrado","loading-text":"Carregando... Por favor, aguarde","footer-props":{
              showFirstLastPage: true,
              'items-per-page-text': 'Linhas por página',
            }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":item.status == 'E' ? false : true,"icon":""},on:{"click":function($event){return _vm.editar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":item.status == 'A' ? false : true,"icon":""},on:{"click":function($event){return _vm.duplicar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-content-duplicate ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.remover(item, true)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)]}},{key:"item.data_hora_inicio",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.data_hora_inicio))+" ")])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",attrs:{"label":"","color":_vm.status[item.status].color}},[_vm._v(_vm._s(_vm.status[item.status].text))])]}},{key:"item.avaliacoes",fn:function(ref){
            var item = ref.item;
return [_c(VChipGroup,[(item.comportamental == 'A')?_c(VChip,{attrs:{"small":"","color":"info"}},[_vm._v("Comportamental")]):_vm._e(),(item.tecnica == 'A')?_c(VChip,{attrs:{"small":"","color":"info"}},[_vm._v("Técnica")]):_vm._e()],1)]}},{key:"item.responsavel",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.responsavel.avatar)?_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"32"}},[_c('img',{attrs:{"src":item.responsavel.avatar,"alt":'Foto de ' + item.responsavel.nome}})]):_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.responsavel.nome)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"},[_vm._v(" "+_vm._s(item.responsavel.nome)+" ")]),_c('small',[_vm._v(_vm._s(item.responsavel.email))])])],1)]}},{key:"footer.page-text",fn:function(item){return [_vm._v(" "+_vm._s(item.pageStart)+" - "+_vm._s(item.pageStop)+" de "+_vm._s(item.itemsLength)+" ")]}}],null,true)})],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2)],1)],1),(_vm.dialogModal)?_c('ModalVisualizarAvaliacaoComponent',{attrs:{"item":_vm.dataDialog,"dialog":_vm.dialogModal},on:{"close-dialog":function($event){return _vm.closeDialog()}}}):_vm._e(),_c('ModalDuplicarAvaliacao',{attrs:{"dialog":_vm.dialogModalDuplicar},on:{"close-modal-duplicar":function($event){_vm.dialogModalDuplicar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }